// BASE.SCSS


/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
	padding-top: 0;
	padding-bottom: 3rem;
	background-color: #000;
}

/* FOR ABOUT PAGE
------------------------------ */
.bg-light {
	background-color: #fff !important;
}

/* FOR GENERAL PAGE
------------------------------ */
.general,
.notfound {
	.jumbotron { background: url(../images/company/hero_company.jpg) center center no-repeat; }
	.contents {
		padding-bottom: 6rem;
		min-height: 25rem;
		text-align: left;
		.page-title {
			text-align: left;
		}
	}
}

/* RESPONSIVE CSS
-------------------------------------------------- */
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
	.navbar {
		padding-top: 0;
	}
	.navbar-nav {
		margin-top: 0;
		flex-wrap: wrap !important;
		.nav-item {
			// white-space: nowrap;
			line-height: 94px;
			background: #fff;
			.nav-link {
				font-weight: bold;
				padding-top: 0;
				padding-bottom: 0;
				color: #000;
			&:hover {
				color: #999;
			}
			}
		}
	}
	.navbar-brand {
		background: url(../images/sk_logo2.png) 0 0 no-repeat;
		background-size: 642px auto;
		margin-top: 0;
	}
}

@media (min-width: 1200px) and (max-width: 1676px) {
	.navbar {
		padding-top: 0;
		background: inherit;
	}
	.bg-light {
		background-color: #fff !important;
	}
	.navbar-nav {
		margin-top: 94px;
		margin-left: -644px;
		.nav-item {
			background: #fff;
			opacity: 0.8;
			line-height: 94px;
			.nav-link {
				font-weight: bold;
				padding-top: 0;
				padding-bottom: 0;
				color: #000;
			&:hover {
				color: #999;
			}
			}
		}
	}
	.navbar-toggler {
		margin-top: -94px;
	}
	.navbar-brand {
		background: url(../images/sk_logo2.png) 0 2px no-repeat;
		background-size: 642px auto;
		margin-top: -94px;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}


// Large devices (desktops, less than 1200px)
@media (max-width: 1199px) {
	.navbar-brand {
		// margin-top: -69px;
		margin: -69px auto 23px;
		height: 70px;
		min-width: 644px;
		background: url(../images/sk_header_logo3.png) 50% 50% no-repeat;
		background-size: 408px auto;
	}
	.navbar-toggler {
		margin-top: -94px;
	}
	.navbar-nav {
		margin-top: 0;
	}
	.navbar-light {
		a {
			color: #333;
		&:hover {
			color: #999;
		}
		}
	}
	.bg-light {
		background-color: #fff !important;
	}
}

// Extra small devices (portrait phones, less than 767px)
@media (max-width: 767px) {
	.navbar-brand {
		// margin-top: -69px;
		margin: -94px auto 0;
		height: auto;
		min-width: 300px;
		background: url(../images/sk_header_logo3.png) 50% 50% no-repeat;
		background-size: auto 40px;
		// margin-bottom: 0;
	}
}